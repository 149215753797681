var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "d-flex flex-wrap w-100 px-sm-4",
        staticStyle: { "background-color": "var(--spainhn-rojo-corporativo)" },
      },
      [
        _c("div", { staticClass: "py-2 px-2 ms-auto" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/reservas" },
            },
            [_c("small", [_vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS")))])]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/cambiaclave" },
            },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_PASSWORD_RESET")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/cambiaemail" },
            },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_EMAIL_CHANGE")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            { staticClass: "link-light", attrs: { href: "/clientes/editar" } },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_EDIT_PROFILE")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2 me-auto" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "#" },
              on: { click: _vm.logout },
            },
            [
              _c("small", [
                _vm._v(" " + _vm._s(_vm.$t("default.CLIENTES_LOGOUT")) + " "),
              ]),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container bg-white my-5 border py-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "spainhn-subtitle-2-black" }, [
            _vm._v(_vm._s(_vm.$t("default.CLIENTES_AREA_TITLE"))),
          ]),
        ]),
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "spainhn-text" }, [
            _vm._v(_vm._s(_vm.$t("default.CLIENTES_WELCOME_TEXT"))),
          ]),
        ]),
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "spainhn-text" }, [
            _vm._v(_vm._s(_vm.$t("default.CLIENTES_ABSTRACT_TEXT"))),
          ]),
        ]),
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap justify-content-lg-around pt-3" },
            [
              _c(
                "div",
                { staticClass: "py-2" },
                [
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button",
                      attrs: {
                        color: "indigo",
                        href: "/clientes/reservas",
                        size: "lg",
                      },
                      on: {
                        click: () => _vm.$router.push("/clientes/reservas"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "py-2" },
                [
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button",
                      attrs: {
                        color: "indigo",
                        href: "/clientes/cambiaemail",
                        size: "lg",
                      },
                      on: {
                        click: () => _vm.$router.push("/clientes/cambiaemail"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.CLIENTES_EMAIL_CHANGE")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "py-2" },
                [
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button",
                      attrs: {
                        color: "indigo",
                        href: "/clientes/cambiaclave",
                        size: "lg",
                      },
                      on: {
                        click: () => _vm.$router.push("/clientes/cambiaclave"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.CLIENTES_PASSWORD_RESET")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "py-2" },
                [
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button",
                      attrs: {
                        color: "indigo",
                        href: "/clientes/editar",
                        size: "lg",
                      },
                      on: { click: () => _vm.$router.push("/clientes/editar") },
                    },
                    [_vm._v(_vm._s(_vm.$t("default.CLIENTES_EDIT_PROFILE")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }